.event1 {
	color: #27c427;
}
.event2 {
	color: #ed1b24;
}
.event3 {
	color: black;
}
.event4 {
	color: #ed1b24;
}
.event5 {
	color: #ed1b24;
}
.event6 {
	color: #ed1b24;
}
.event7 {
	color: #ed1b24;
}
.event8 {
	color: #ed1b24;
}
.event9 {
	color: #27c427;
}
.event10 {
	color: #ed1b24;
}
.event11 {
	color: #ed1b24;
}
.event12 {
	color: #ed1b24;
}
.event13 {
	color: #ed1b24;
}
.event14 {
	color: #fbff00;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #000;
}
.event15 {
	color: #ed1b24;
}
.event16 {
	color: #ed1b24;
}
.event17 {
	color: #ed1b24;
}
.event18 {
	color: #ed1b24;
}
.event19 {
	color: #ed1b24;
}
.event20 {
	color: #ed1b24;
}
.event21 {
	color: #ed1b24;
}
.event22 {
	color: black;
}
.event46 {
	color: #ed1b24;
}

.event35,
.event36,
.event37,
.event38,
.event39,
.event40,
.event41,
.event42 {
	&.siren {
		color: #d0d2d3;
		animation: sirena-active 0.35s infinite;
	}
}

@keyframes sirena-active {
	from {
		color: #0000ff;
	}
	to {
		color: #ff0000;
	}
}

@keyframes lds-facebook {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}

.deviceInfoTmp {
	padding-top: 5px;
	overflow: hidden;
	.deviceInfoHiddenDiv {
		max-height: 0;
		&.showInfo {
			-moz-transition: height 0.5s;
			-ms-transition: height 0.5s;
			-o-transition: height 0.5s;
			-webkit-transition: height 0.5s;
			transition: height 0.5s;
			max-height: 300px;
		}
		.img {
			height: 50px;
			background-size: contain;
			background-position: left;
			margin-bottom: 5px;
			background-repeat: no-repeat;
		}
	}
	.showInfoDiv {
		cursor: pointer;
		font-weight: bold;
	}
	.deviceInfoContainer {
		padding-top: 5px;
		h4 {
			color: #585858;
		}
	}
}
// Media query for screens smaller than 768px (e.g., smartphones)
@media only screen and (max-width: 767px) {
	.info-window {
		width: 57vw;
	}
}

// Media query for screens between 768px and 1024px (e.g., tablets)
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.info-window {
		width: 35vw;
	}
}

// Media query for screens larger than 1024px (e.g., desktops)
@media only screen and (min-width: 1025px) {
	.info-window {
		width: 384px;
	}
}
.info-window {
	padding: 10px 10px 0px;
	overflow: hidden;

	&-scroll {
		max-height: 430px;
		overflow: hidden auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	&-icon {
		text-align: center;
		img {
			width: 100%;
			padding: 15px 0;
		}
	}
	&-collapse-arrow {
		text-align: center;
		font-size: 20px;
		font-weight: bold;
	}
	&-title {
		padding: 5px 20px 5px 0;
		font-size: 16px;
		font-weight: bold;
		border-bottom: 1px solid #f0f0f1;
	}
	&-content {
		padding: 15px 0;
		& .ant-descriptions-item {
			padding-bottom: 0 !important;
		}
	}
	&-driver-image {
		text-align: center;
		&-share {
			padding: 5px 0;
		}
	}
	&-obd {
		height: 120px;
		border-top: 1px solid #f0f0f1;
		padding: 10px 0;
		&-title {
			font-size: 16px;
			padding: 5px 0;
		}
		&-information {
			overflow-y: scroll;
			height: 70px;
		}
	}
	&-device-info {
		&-carousel {
			height: 180px;
			border-top: 1px solid #f0f0f1;
			border-bottom: 1px solid #f0f0f1;
			padding: 10px 0;
			&-dots {
				padding: 10px 0;
				li {
					width: 10px !important;
				}
				button {
					background: #d9d9d9 !important;
					width: 10px !important;
					height: 10px !important;
					border-radius: 100px !important;
				}
				.slick-active {
					button {
						background: #262626 !important;
					}
				}
			}
			&-image {
				width: 100%;
				img {
					width: 100%;
					padding-right: 5px;
					border-radius: 50%;
					scrollbar-width: thin;
				}
			}
			&-container {
				overflow-y: auto;
				height: 130px;
				&-title {
					font-size: 16px;
					padding: 5px 0;
				}
			}
		}
	}
	&-actions-container {
		width: 100%;
		padding: 15px 0;
	}
	&-info-label {
		padding-right: 5px;
	}
}

.linkContainer {
	display: flex;
	padding: 0;
	margin: 0;
	flex-wrap: wrap;
	width: 100%;
}

.leftSide {
	padding: 10px;
	margin-right: auto;
}

.rightSide {
	padding: 10px;
	margin-left: auto;
}
