.canbus-Tabs-Header {
  display: flex;
  
  &--canbusTitle {
    margin-left: auto;
    padding-right: 20px;
  }
}
.canbus-tabs > .ant-tabs-nav {
  padding-left: 20px;
}
