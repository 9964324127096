@import '../../src/styles/constants';

$box-shadow: 0 1px 4px 0 #a7a7a761;
$border-radius: 12px;
$title-color: #505050;

.overview-background {
    height: inherit;
    background: #ffffff;
}

.overview {
    padding: 0 2rem;
    padding-top: 2rem;
    margin-left: 0 !important;
    margin-right: 0 !important;

    &-title {
        color: $title-color;
        font-weight: 700;
        font-size: 16px;
    }

    &-col {
        padding: 0 1rem;
    }

    .inner-space {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &-card {
        box-shadow: $box-shadow;
        border-radius: $border-radius;
    }

    .pie {
        canvas {
            height: 26rem;
        }
    }
}

.daily-chart {
    width: 100%;
    padding: 15px;
    padding-top: 12px;
    padding-bottom: 20px;

    .ant-spin-container {
        display: flex;
        justify-content: center;
        min-height: 18.5rem;
        max-height: 27rem;
    }

    .date-subtitle {
        display: flex;
        justify-content: center;
        color: darkgray;
        font-size: 14px;
        font-weight: 400;
    }

    .ant-spin-nested-loading {
        width: 100%;
    }

    .overview-title,
    .spinner {
        display: flex;
        justify-content: center;
    }

    .empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem 0rem;
        min-height: 26.4rem;
    }

    .chart-container {
        min-height: 27.5rem;

        .pie {
            width: 100%;
            display: flex;
            justify-content: center;

            canvas {
                width: 100%;
            }
        }
    }

    .ant-empty-description {
        color: #aeb8c2;
    }
}

.task-calendar {
    padding: 15px 25px;

    li::marker {
        content: '';
    }

    dl,
    ol,
    ul {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        gap: 4px;
        height: 6px;
        position: absolute;
        width: 100%;
    }

    span.ant-select-arrow {
        color: transparent;
    }
}

.quick-access {
    width: 100%;

    .overview-title {
        padding-bottom: 6px;
    }

    button.ant-btn.ant-btn-text {
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        padding: 0px 16px;
        height: 3.1rem;
    }

    button.ant-btn.ant-btn-text.ant-btn-block {
        &.task-planner {
            background: #329AFF;

            .qa-label,
            .qa-icon {
                color: #ffffff;
            }
        }

        &:hover {
            background-color: #2382de;

            .qa-label,
            .qa-icon {
                color: #ffffff;
            }
        }
    }

    button.ant-btn.ant-btn-text.ant-btn-block {
        &.tasks-management {
            background: #ffffff;

            .qa-label,
            .qa-icon {
                color: #329AFF;
            }

        }

        &:hover {
            background-color: #2382de;

            .qa-label,
            .qa-icon {
                color: white;
            }
        }
    }

    &-button {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .qa-icon {
            color: #ffffff;
            padding: 0px 5px;
            border-radius: 6px;
            padding-top: 1px;
        }

        .qa-label {
            color: white;
            font-size: 15px;
            font-weight: 400;
            text-transform: capitalize;
        }
    }
}

.calendar-list {
    display: flex;
    align-items: center;

    &-container {
        display: flex;
        flex-direction: column;
        padding: 10px 15px;
        gap: 16px;
        padding-left: 18px;
    }

    &.total {
        font-weight: bold;
    }

    .quantity {
        display: flex;
        justify-content: flex-end;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #1890FF;
    }

    .icon-task {
        color: #1890FF;
        font-size: 20px;
    }

    .ant-spin-container {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .skip-icon {
        background: #1990ff;
        color: white;
        border-radius: 20rem;
        padding: 3px;
        font-size: 10px;
    }

    &:hover {
        background: #f4f4f478;
    }
}

.pending-card-detail {
    padding: 20px;
    height: 14rem;

    .list-container {
        height: 70px;
        color: #808080;

        .total {
            text-align: right;
        }
    }

    .count {
        font-size: 30px;
        font-weight: 700;
    }

    .ant-divider.ant-divider-horizontal {
        margin: 6px 0px;
        margin-bottom: 12px;
    }

    button.ant-btn.ant-btn-default {
        width: 100%;
        border-radius: 8px;
    }

    .unassigned {
        color: $unassigned;
    }

    .uncompleted {
        color: $uncompleted;
    }

    .ant-empty {
        font-size: 12px;
    }

    .ant-empty-normal .ant-empty-image {
        height: 36px;
    }

    .ant-empty-normal {
        margin: 30px 0;
    }
}

/* 4K Desktops */
@media only screen and (min-width: 3840px) {
    .overview {
        margin-left: auto !important;
        margin-right: auto !important;
        width: 60%;
        height: inherit;
        background: #ffffff;
    }

    .overview-background {
        height: inherit;
        background: #f7f7f7;
    }
}

/* Full HD Desktops */
@media only screen and (min-width: 1920px) and (max-width: 3839px) {
    .overview {
        margin-left: auto !important;
        margin-right: auto !important;
        width: 70%;
        height: inherit;
        background: #ffffff;

        .pie {
            canvas {
                height: 26rem !important;
            }
        }
    }

    .overview-background {
        height: inherit;
        background: #f7f7f7;
    }
}

/* Big Laptops */
@media only screen and (min-width: 1440px) and (max-width: 1919px) {}

/* Normal Laptops */
@media only screen and (min-width: 1024px) and (max-width: 1439px) {}

/* iPads (Tablets) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {}

/* Phones */
@media only screen and (max-width: 767px) {
    .overview,
    .calendar-pending-cards {
        flex-direction: column-reverse !important;
    }

    .calendar-pending-cards {
        margin-bottom: 40px;
    }

    .daily-chart .chart-container {
        min-height: 16.5rem;
    }
}