@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?fed2sg');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?fed2sg#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?fed2sg') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?fed2sg') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?fed2sg##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-can {
  &:before {
    content: $icon-can; 
  }
}
.icon-operator {
  &:before {
    content: $icon-operator; 
  }
}
.icon-ignition {
  &:before {
    content: $icon-ignition; 
  }
}
.icon-key-Inserted {
  &:before {
    content: $icon-key-Inserted; 
  }
}
.icon-abs {
  &:before {
    content: $icon-abs; 
  }
}
.icon-ac-turned-on {
  &:before {
    content: $icon-ac-turned-on; 
  }
}
.icon-airbag {
  &:before {
    content: $icon-airbag; 
  }
}
.icon-car-has-being-closed-using-original-remote-or-module-command {
  &:before {
    content: $icon-car-has-being-closed-using-original-remote-or-module-command; 
  }
}
.icon-central-differential-locker {
  &:before {
    content: $icon-central-differential-locker; 
  }
}
.icon-central-differential-with-reductor-4lo {
  &:before {
    content: $icon-central-differential-with-reductor-4lo; 
  }
}
.icon-check-engine {
  &:before {
    content: $icon-check-engine; 
  }
}
.icon-clutch {
  &:before {
    content: $icon-clutch; 
  }
}
.icon-coolant-temperature-level {
  &:before {
    content: $icon-coolant-temperature-level; 
  }
}
.icon-cruise-control {
  &:before {
    content: $icon-cruise-control; 
  }
}
.icon-dipped-lights {
  &:before {
    content: $icon-dipped-lights; 
  }
}
.icon-drive {
  &:before {
    content: $icon-drive; 
  }
}
.icon-electronic-stability-program-off {
  &:before {
    content: $icon-electronic-stability-program-off; 
  }
}
.icon-electronics-stability-control {
  &:before {
    content: $icon-electronics-stability-control; 
  }
}
.icon-footbrake {
  &:before {
    content: $icon-footbrake; 
  }
}
.icon-front-fog-lights {
  &:before {
    content: $icon-front-fog-lights; 
  }
}
.icon-full-beam-headlights {
  &:before {
    content: $icon-full-beam-headlights; 
  }
}
.icon-glow-plug-indicator {
  &:before {
    content: $icon-glow-plug-indicator; 
  }
}
.icon-hand-brake-system-indicator {
  &:before {
    content: $icon-hand-brake-system-indicator; 
  }
}
.icon-seat-belt {
  &:before {
    content: $icon-seat-belt; 
  }
}
.icon-low-tire-pressure {
  &:before {
    content: $icon-low-tire-pressure; 
  }
}
.icon-neutral {
  &:before {
    content: $icon-neutral; 
  }
}
.icon-oil-pressure-level {
  &:before {
    content: $icon-oil-pressure-level; 
  }
}
.icon-parking-lights {
  &:before {
    content: $icon-parking-lights; 
  }
}
.icon-Parking {
  &:before {
    content: $icon-Parking; 
  }
}
.icon-particulate-filter {
  &:before {
    content: $icon-particulate-filter; 
  }
}
.icon-rear-differential-locker {
  &:before {
    content: $icon-rear-differential-locker; 
  }
}
.icon-rear-fog-lights {
  &:before {
    content: $icon-rear-fog-lights; 
  }
}
.icon-reverse {
  &:before {
    content: $icon-reverse; 
  }
}
.icon-engine-cover-hood {
  &:before {
    content: $icon-engine-cover-hood; 
  }
}
.icon-rear-right-door {
  &:before {
    content: $icon-rear-right-door; 
  }
}
.icon-rear-left-door {
  &:before {
    content: $icon-rear-left-door; 
  }
}
.icon-front-right-door {
  &:before {
    content: $icon-front-right-door; 
  }
}
.icon-front-left-door {
  &:before {
    content: $icon-front-left-door; 
  }
}
.icon-sort {
  &:before {
    content: $icon-sort; 
  }
}
.icon-completed-report {
  &:before {
    content: $icon-completed-report; 
  }
}
.icon-multimedia .path1 {
  &:before {
    content: $icon-multimedia-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-multimedia .path2 {
  &:before {
    content: $icon-multimedia-path2;  
    margin-left: -1em;  
    color: rgb(230, 247, 255);
  }
}
.icon-multimedia .path3 {
  &:before {
    content: $icon-multimedia-path3;  
    margin-left: -1em;  
    color: rgb(40, 151, 255);
  }
}
.icon-multimedia .path4 {
  &:before {
    content: $icon-multimedia-path4;  
    margin-left: -1em;  
    color: rgb(40, 151, 255);
  }
}
.icon-multimedia .path5 {
  &:before {
    content: $icon-multimedia-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-multimedia .path6 {
  &:before {
    content: $icon-multimedia-path6;  
    margin-left: -1em;  
    color: rgb(230, 247, 255);
  }
}
.icon-multimedia .path7 {
  &:before {
    content: $icon-multimedia-path7;  
    margin-left: -1em;  
    color: rgb(40, 151, 255);
  }
}
.icon-multimedia .path8 {
  &:before {
    content: $icon-multimedia-path8;  
    margin-left: -1em;  
    color: rgb(40, 151, 255);
  }
}
.icon-multimedia .path9 {
  &:before {
    content: $icon-multimedia-path9;  
    margin-left: -1em;  
    color: rgb(40, 151, 255);
  }
}
.icon-unlink {
  &:before {
    content: $icon-unlink;     
    color: #afafaf;
  }
}
.icon-pickup-pin {
  &:before {
    content: $icon-pickup-pin;     
    color: #afafaf;
  }
}
.icon-delivery-pin {
  &:before {
    content: $icon-delivery-pin;     
    color: #afafaf;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;     
    color: #afafaf;
  }
}
.icon-view {
  &:before {
    content: $icon-view;     
    color: #afafaf;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;     
    color: #afafaf;
  }
}
.icon-description {
  &:before {
    content: $icon-description;     
    color: #4c4c4c;
  }
}
.icon-task {
  &:before {
    content: $icon-task;     
    color: #4c4c4c;
  }
}
.icon-layers {
  &:before {
    content: $icon-layers; 
  }
}
.icon-toolbarcalendar {
  &:before {
    content: $icon-toolbarcalendar; 
  }
}
.icon-share-round .path1 {
  &:before {
    content: $icon-share-round-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-share-round .path2 {
  &:before {
    content: $icon-share-round-path2;  
    margin-left: -1em;  
    color: rgb(175, 175, 175);
  }
}
.icon-direct-link {
  &:before {
    content: $icon-direct-link;     
    color: #afafaf;
  }
}
.icon-text-message {
  &:before {
    content: $icon-text-message;     
    color: #afafaf;
  }
}
.icon-otp-access-code {
  &:before {
    content: $icon-otp-access-code;     
    color: #afafaf;
  }
}
.icon-multimedia-video .path1 {
  &:before {
    content: $icon-multimedia-video-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-multimedia-video .path2 {
  &:before {
    content: $icon-multimedia-video-path2;  
    margin-left: -1em;  
    color: rgb(230, 247, 255);
  }
}
.icon-multimedia-video .path3 {
  &:before {
    content: $icon-multimedia-video-path3;  
    margin-left: -1em;  
    color: rgb(40, 151, 255);
  }
}
.icon-multimedia-video .path4 {
  &:before {
    content: $icon-multimedia-video-path4;  
    margin-left: -1em;  
    color: rgb(40, 151, 255);
  }
}
.icon-multimedia-photo .path1 {
  &:before {
    content: $icon-multimedia-photo-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-multimedia-photo .path2 {
  &:before {
    content: $icon-multimedia-photo-path2;  
    margin-left: -1em;  
    color: rgb(230, 247, 255);
  }
}
.icon-multimedia-photo .path3 {
  &:before {
    content: $icon-multimedia-photo-path3;  
    margin-left: -1em;  
    color: rgb(40, 151, 255);
  }
}
.icon-multimedia-photo .path4 {
  &:before {
    content: $icon-multimedia-photo-path4;  
    margin-left: -1em;  
    color: rgb(40, 151, 255);
  }
}
.icon-multimedia-photo .path5 {
  &:before {
    content: $icon-multimedia-photo-path5;  
    margin-left: -1em;  
    color: rgb(40, 151, 255);
  }
}
.icon-multimedia-video-map .path1 {
  &:before {
    content: $icon-multimedia-video-map-path1;  
    color: rgb(0, 0, 0);
  }
}
.icon-multimedia-video-map .path2 {
  &:before {
    content: $icon-multimedia-video-map-path2;  
    margin-left: -1em;  
    color: rgb(244, 206, 211);
  }
}
.icon-multimedia-video-map .path3 {
  &:before {
    content: $icon-multimedia-video-map-path3;  
    margin-left: -1em;  
    color: rgb(190, 30, 45);
  }
}
.icon-multimedia-video-map .path4 {
  &:before {
    content: $icon-multimedia-video-map-path4;  
    margin-left: -1em;  
    color: rgb(190, 30, 45);
  }
}
.icon-multimedia-video-map .path5 {
  &:before {
    content: $icon-multimedia-video-map-path5;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-multimedia-map .path1 {
  &:before {
    content: $icon-multimedia-map-path1;  
    color: rgb(0, 0, 0);
  }
}
.icon-multimedia-map .path2 {
  &:before {
    content: $icon-multimedia-map-path2;  
    margin-left: -1em;  
    color: rgb(244, 206, 211);
  }
}
.icon-multimedia-map .path3 {
  &:before {
    content: $icon-multimedia-map-path3;  
    margin-left: -1em;  
    color: rgb(190, 30, 45);
  }
}
.icon-multimedia-map .path4 {
  &:before {
    content: $icon-multimedia-map-path4;  
    margin-left: -1em;  
    color: rgb(190, 30, 45);
  }
}
.icon-multimedia-map .path5 {
  &:before {
    content: $icon-multimedia-map-path5;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-multimedia-map .path6 {
  &:before {
    content: $icon-multimedia-map-path6;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-multimedia-map .path7 {
  &:before {
    content: $icon-multimedia-map-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-multimedia-map .path8 {
  &:before {
    content: $icon-multimedia-map-path8;  
    margin-left: -1em;  
    color: rgb(83, 184, 219);
  }
}
.icon-multimedia-map .path9 {
  &:before {
    content: $icon-multimedia-map-path9;  
    margin-left: -1em;  
    color: rgb(249, 207, 28);
  }
}
.icon-multimedia-map .path10 {
  &:before {
    content: $icon-multimedia-map-path10;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-multimedia-map .path11 {
  &:before {
    content: $icon-multimedia-map-path11;  
    margin-left: -1em;  
    color: rgb(79, 198, 90);
  }
}
.icon-multimedia-map .path12 {
  &:before {
    content: $icon-multimedia-map-path12;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-multimedia-photo-map .path1 {
  &:before {
    content: $icon-multimedia-photo-map-path1;  
    color: rgb(0, 0, 0);
  }
}
.icon-multimedia-photo-map .path2 {
  &:before {
    content: $icon-multimedia-photo-map-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-multimedia-photo-map .path3 {
  &:before {
    content: $icon-multimedia-photo-map-path3;  
    margin-left: -1em;  
    color: rgb(83, 184, 219);
  }
}
.icon-multimedia-photo-map .path4 {
  &:before {
    content: $icon-multimedia-photo-map-path4;  
    margin-left: -1em;  
    color: rgb(249, 207, 28);
  }
}
.icon-multimedia-photo-map .path5 {
  &:before {
    content: $icon-multimedia-photo-map-path5;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-multimedia-photo-map .path6 {
  &:before {
    content: $icon-multimedia-photo-map-path6;  
    margin-left: -1em;  
    color: rgb(79, 198, 90);
  }
}
.icon-multimedia-photo-map .path7 {
  &:before {
    content: $icon-multimedia-photo-map-path7;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-inactivity {
  &:before {
    content: $icon-inactivity; 
  }
}
.icon-route-type {
  &:before {
    content: $icon-route-type; 
  }
}
.icon-optimize {
  &:before {
    content: $icon-optimize; 
  }
}
.icon-deallocate {
  &:before {
    content: $icon-deallocate; 
  }
}
.icon-active {
  &:before {
    content: $icon-active; 
  }
}
.icon-pin {
  &:before {
    content: $icon-pin; 
  }
}
.icon-tasks {
  &:before {
    content: $icon-tasks; 
  }
}
.icon-driver {
  &:before {
    content: $icon-driver; 
  }
}
.icon-in-progress {
  &:before {
    content: $icon-in-progress; 
  }
}
.icon-route-reports {
  &:before {
    content: $icon-route-reports; 
  }
}
.icon-schedule-truck {
  &:before {
    content: $icon-schedule-truck; 
  }
}
.icon-evening {
  &:before {
    content: $icon-evening; 
  }
}
.icon-morning {
  &:before {
    content: $icon-morning; 
  }
}
.icon-afternoon {
  &:before {
    content: $icon-afternoon; 
  }
}
.icon-speedBingReport {
  &:before {
    content: $icon-speedBingReport; 
  }
}
.icon-add-device {
  &:before {
    content: $icon-add-device; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-world {
  &:before {
    content: $icon-world; 
  }
}
.icon-activateDeactivateSimCard {
  &:before {
    content: $icon-activateDeactivateSimCard; 
  }
}
.icon-drivers {
  &:before {
    content: $icon-drivers; 
  }
}
.icon-idle {
  &:before {
    content: $icon-idle; 
  }
}
.icon-fuel-control {
  &:before {
    content: $icon-fuel-control; 
  }
}
.icon-sukarneTrips {
  &:before {
    content: $icon-sukarneTrips; 
  }
}
.icon-police-station {
  &:before {
    content: $icon-police-station; 
  }
}
.icon-battery-voltage-1 {
  &:before {
    content: $icon-battery-voltage-1; 
  }
}
.icon-battery-voltage-2 {
  &:before {
    content: $icon-battery-voltage-2; 
  }
}
.icon-malfunction-indicador {
  &:before {
    content: $icon-malfunction-indicador; 
  }
}
.icon-automaticReport {
  &:before {
    content: $icon-automaticReport; 
  }
}
.icon-currentState {
  &:before {
    content: $icon-currentState; 
  }
}
.icon-media {
  &:before {
    content: $icon-media; 
  }
}
.icon-driving {
  &:before {
    content: $icon-driving; 
  }
}
.icon-eventsReports {
  &:before {
    content: $icon-eventsReports; 
  }
}
.icon-fuel {
  &:before {
    content: $icon-fuel; 
  }
}
.icon-geofences {
  &:before {
    content: $icon-geofences; 
  }
}
.icon-loginHistory {
  &:before {
    content: $icon-loginHistory; 
  }
}
.icon-parking {
  &:before {
    content: $icon-parking; 
  }
}
.icon-photos {
  &:before {
    content: $icon-photos; 
  }
}
.icon-pointOfInterest {
  &:before {
    content: $icon-pointOfInterest; 
  }
}
.icon-sentCommand {
  &:before {
    content: $icon-sentCommand; 
  }
}
.icon-speed {
  &:before {
    content: $icon-speed; 
  }
}
.icon-temperature {
  &:before {
    content: $icon-temperature; 
  }
}
.icon-unitLogReport {
  &:before {
    content: $icon-unitLogReport; 
  }
}
.icon-unitsPerClients {
  &:before {
    content: $icon-unitsPerClients; 
  }
}
.icon-admin {
  &:before {
    content: $icon-admin; 
  }
}
.icon-clients {
  &:before {
    content: $icon-clients; 
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard; 
  }
}
.icon-googleEarth {
  &:before {
    content: $icon-googleEarth; 
  }
}
.icon-groups {
  &:before {
    content: $icon-groups; 
  }
}
.icon-maintenance {
  &:before {
    content: $icon-maintenance; 
  }
}
.icon-map {
  &:before {
    content: $icon-map; 
  }
}
.icon-multipleMap {
  &:before {
    content: $icon-multipleMap; 
  }
}
.icon-notification {
  &:before {
    content: $icon-notification; 
  }
}
.icon-reports {
  &:before {
    content: $icon-reports; 
  }
}
.icon-sensors {
  &:before {
    content: $icon-sensors; 
  }
}
.icon-services {
  &:before {
    content: $icon-services; 
  }
}
.icon-sims {
  &:before {
    content: $icon-sims; 
  }
}
.icon-unitLog {
  &:before {
    content: $icon-unitLog; 
  }
}
.icon-units {
  &:before {
    content: $icon-units; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-weeklyMap {
  &:before {
    content: $icon-weeklyMap; 
  }
}
.icon-weeklyRoute {
  &:before {
    content: $icon-weeklyRoute; 
  }
}
.icon-cold {
  &:before {
    content: $icon-cold; 
  }
}
.icon-hot {
  &:before {
    content: $icon-hot; 
  }
}
.icon-friday {
  &:before {
    content: $icon-friday; 
  }
}
.icon-monday {
  &:before {
    content: $icon-monday; 
  }
}
.icon-saturday {
  &:before {
    content: $icon-saturday; 
  }
}
.icon-sunday {
  &:before {
    content: $icon-sunday; 
  }
}
.icon-thursday {
  &:before {
    content: $icon-thursday; 
  }
}
.icon-tuesday {
  &:before {
    content: $icon-tuesday; 
  }
}
.icon-wednesday {
  &:before {
    content: $icon-wednesday; 
  }
}
.icon-broom {
  &:before {
    content: $icon-broom; 
  }
}
.icon-architecture {
  &:before {
    content: $icon-architecture; 
  }
}
.icon-bed {
  &:before {
    content: $icon-bed; 
  }
}
.icon-placeholder .path1 {
  &:before {
    content: $icon-placeholder-path1;  
    color: rgb(212, 61, 33);
  }
}
.icon-placeholder .path2 {
  &:before {
    content: $icon-placeholder-path2;  
    margin-left: -1em;  
    color: rgb(247, 241, 230);
  }
}
.icon-placeholder .path3 {
  &:before {
    content: $icon-placeholder-path3;  
    margin-left: -1em;  
    color: rgb(231, 68, 36);
  }
}
.icon-police-officer {
  &:before {
    content: $icon-police-officer; 
  }
}
.icon-coffee {
  &:before {
    content: $icon-coffee; 
  }
}
.icon-sensor {
  &:before {
    content: $icon-sensor; 
  }
}
.icon-units1 {
  &:before {
    content: $icon-units1; 
  }
}
.icon-units2 {
  &:before {
    content: $icon-units2; 
  }
}
.icon-units3 {
  &:before {
    content: $icon-units3; 
  }
}
.icon-units4 {
  &:before {
    content: $icon-units4; 
  }
}
.icon-earth {
  &:before {
    content: $icon-earth; 
  }
}
.icon-beer {
  &:before {
    content: $icon-beer; 
  }
}
.icon-rule {
  &:before {
    content: $icon-rule; 
  }
}
.icon-location-on-road {
  &:before {
    content: $icon-location-on-road; 
  }
}
.icon-delivery {
  &:before {
    content: $icon-delivery; 
  }
}
.icon-pickup {
  &:before {
    content: $icon-pickup; 
  }
}
.icon-presentation {
  &:before {
    content: $icon-presentation; 
  }
}
.icon-flag {
  &:before {
    content: $icon-flag; 
  }
}
.icon-flag2 {
  &:before {
    content: $icon-flag2; 
  }
}
.icon-checkered-flag {
  &:before {
    content: $icon-checkered-flag; 
  }
}
.icon-arrow_up {
  &:before {
    content: $icon-arrow_up; 
  }
}
.icon-arrow_down {
  &:before {
    content: $icon-arrow_down; 
  }
}
.icon-drag_indicator {
  &:before {
    content: $icon-drag_indicator; 
  }
}
.icon-bar {
  &:before {
    content: $icon-bar; 
  }
}
.icon-flight {
  &:before {
    content: $icon-flight; 
  }
}
.icon-my_location {
  &:before {
    content: $icon-my_location; 
  }
}
.icon-sim_card {
  &:before {
    content: $icon-sim_card; 
  }
}
.icon-battery_alert {
  &:before {
    content: $icon-battery_alert; 
  }
}
.icon-flash_off {
  &:before {
    content: $icon-flash_off; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-gas-station {
  &:before {
    content: $icon-gas-station; 
  }
}
.icon-travel-bus {
  &:before {
    content: $icon-travel-bus; 
  }
}
.icon-price-tag {
  &:before {
    content: $icon-price-tag; 
  }
}
.icon-restaurant {
  &:before {
    content: $icon-restaurant; 
  }
}
.icon-volume-mute {
  &:before {
    content: $icon-volume-mute; 
  }
}
.icon-volume {
  &:before {
    content: $icon-volume; 
  }
}
.icon-audio {
  &:before {
    content: $icon-audio; 
  }
}
.icon-player {
  &:before {
    content: $icon-player; 
  }
}
.icon-volume-high {
  &:before {
    content: $icon-volume-high; 
  }
}
.icon-volume2 {
  &:before {
    content: $icon-volume2; 
  }
}
.icon-audio2 {
  &:before {
    content: $icon-audio2; 
  }
}
.icon-speaker {
  &:before {
    content: $icon-speaker; 
  }
}
.icon-player2 {
  &:before {
    content: $icon-player2; 
  }
}
.icon-truck2 {
  &:before {
    content: $icon-truck2; 
  }
}
.icon-radio-tower {
  &:before {
    content: $icon-radio-tower; 
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-address-book {
  &:before {
    content: $icon-address-book; 
  }
}
.icon-envelop {
  &:before {
    content: $icon-envelop; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-location2 {
  &:before {
    content: $icon-location2; 
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile; 
  }
}
.icon-floppy-disk {
  &:before {
    content: $icon-floppy-disk; 
  }
}
.icon-redo2 {
  &:before {
    content: $icon-redo2; 
  }
}
.icon-users {
  &:before {
    content: $icon-users; 
  }
}
.icon-user-tie {
  &:before {
    content: $icon-user-tie; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-bin {
  &:before {
    content: $icon-bin; 
  }
}
.icon-switch {
  &:before {
    content: $icon-switch; 
  }
}
.icon-file-excel {
  &:before {
    content: $icon-file-excel; 
  }
}
.icon-anchor {
  &:before {
    content: $icon-anchor; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-basket {
  &:before {
    content: $icon-basket; 
  }
}
.icon-truck {
  &:before {
    content: $icon-truck; 
  }
}
.icon-automobile {
  &:before {
    content: $icon-automobile; 
  }
}
.icon-car {
  &:before {
    content: $icon-car; 
  }
}
.icon-paper-plane-o {
  &:before {
    content: $icon-paper-plane-o; 
  }
}
.icon-send-o {
  &:before {
    content: $icon-send-o; 
  }
}
.icon-motorcycle {
  &:before {
    content: $icon-motorcycle; 
  }
}
.icon-battery {
  &:before {
    content: $icon-battery; 
  }
}
.icon-battery-4 {
  &:before {
    content: $icon-battery-4; 
  }
}
.icon-battery-full {
  &:before {
    content: $icon-battery-full; 
  }
}
.icon-battery-3 {
  &:before {
    content: $icon-battery-3; 
  }
}
.icon-battery-three-quarters {
  &:before {
    content: $icon-battery-three-quarters; 
  }
}
.icon-battery-2 {
  &:before {
    content: $icon-battery-2; 
  }
}
.icon-battery-half {
  &:before {
    content: $icon-battery-half; 
  }
}
.icon-battery-1 {
  &:before {
    content: $icon-battery-1; 
  }
}
.icon-battery-quarter {
  &:before {
    content: $icon-battery-quarter; 
  }
}
.icon-battery-0 {
  &:before {
    content: $icon-battery-0; 
  }
}
.icon-battery-empty {
  &:before {
    content: $icon-battery-empty; 
  }
}
.icon-thermometer1 {
  &:before {
    content: $icon-thermometer1; 
  }
}
.icon-thermometer-4 {
  &:before {
    content: $icon-thermometer-4; 
  }
}
.icon-thermometer-full {
  &:before {
    content: $icon-thermometer-full; 
  }
}
.icon-thermometer-3 {
  &:before {
    content: $icon-thermometer-3; 
  }
}
.icon-thermometer-three-quarters {
  &:before {
    content: $icon-thermometer-three-quarters; 
  }
}
.icon-thermometer-2 {
  &:before {
    content: $icon-thermometer-2; 
  }
}
.icon-thermometer-half {
  &:before {
    content: $icon-thermometer-half; 
  }
}
.icon-thermometer-1 {
  &:before {
    content: $icon-thermometer-1; 
  }
}
.icon-thermometer-quarter {
  &:before {
    content: $icon-thermometer-quarter; 
  }
}
.icon-thermometer-0 {
  &:before {
    content: $icon-thermometer-0; 
  }
}
.icon-thermometer-empty {
  &:before {
    content: $icon-thermometer-empty; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-warning2 {
  &:before {
    content: $icon-warning2; 
  }
}
.icon-search2 {
  &:before {
    content: $icon-search2; 
  }
}
.icon-envelope-o {
  &:before {
    content: $icon-envelope-o; 
  }
}
.icon-user2 {
  &:before {
    content: $icon-user2; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-remove {
  &:before {
    content: $icon-remove; 
  }
}
.icon-times {
  &:before {
    content: $icon-times; 
  }
}
.icon-trash-o {
  &:before {
    content: $icon-trash-o; 
  }
}
.icon-ban {
  &:before {
    content: $icon-ban; 
  }
}
.icon-mail-forward {
  &:before {
    content: $icon-mail-forward; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-group {
  &:before {
    content: $icon-group; 
  }
}
.icon-users2 {
  &:before {
    content: $icon-users2; 
  }
}
.icon-circle {
  &:before {
    content: $icon-circle; 
  }
}
.icon-paper-plane-o2 {
  &:before {
    content: $icon-paper-plane-o2; 
  }
}
.icon-send-o2 {
  &:before {
    content: $icon-send-o2; 
  }
}
.icon-podcast {
  &:before {
    content: $icon-podcast; 
  }
}
.icon-drivers-license {
  &:before {
    content: $icon-drivers-license; 
  }
}

