.groups {
	width: 100%;
	.groupCard {
		margin: 15px 0;
		.ant-card-meta-description{
			height: 80px;
		}
		.footer {
			bottom: 30px;
			right: 0;
			font-weight: bold;
			position: absolute;
			text-align: right;
		}
		.body {
			height: 100%;
			text-align: center;
		}
		.icon {
			font-size: 15px;
			margin-right: 5px;
		}
		.ant-card-actions {
			bottom: 0;
			position: absolute;
			width: 100%;
		}
	}
	.newGroupButton{
		width: 100%;
		height: 178px;
		margin-top: 15px;
	}
}