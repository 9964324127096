// * This are the classes applied to the ant-d when displaying the screen on devices
@media only screen and (max-width: 767px) {
    // Modal
    .ant-modal {
        max-width: 100%;
    }
    .ant-modal.stepper, .drivers-device-modal.ant-modal {
        top: 0;
        margin: 0;
        padding: 0;
        height: 100%;
    }
    .ant-modal-content {
        border-radius: 0px;
        height: 100%;
    }
    .ant-steps-vertical {
        display: flex;
        flex-direction: row;
        padding-left: 32px;
    }
    .ant-modal-body {
        background: #ffffff;
    }
    .ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail:after {
        width: calc(100% - 28px);
        height: 3px;        
    }
    .ant-steps-dot .ant-steps-item-tail:after, .ant-steps-dot.ant-steps-small .ant-steps-item-tail:after {
        margin-left: 30px;
    }
    .ant-steps-vertical.ant-steps-dot .ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
        left: 0;
        top: 2px;
        width: 100%;
        margin: 13px 0 0 -13px;
        padding: 0;
    }
    .ant-steps-vertical>.ant-steps-item:last-child {
        flex: none;
    }

    // Buttons
    .ant-btn.ant-btn-block {
        border-radius: 2rem;
        text-align-last: center;
    }

    // share app modal
    .ant-modal.share-app {
        padding: 12px;

        .ant-modal-content, .ant-modal-body {
            border-radius: 9px;
        }
    }
}