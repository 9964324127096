.twilioForm, .sendGridForm, .mailForm {
	.label {
		padding: 20px 0 10px;
	}
}

.twilioForm {
	.disclamer {
		font-size: 11px;
		.asterisc {
			color: #f5222d;
			padding: 5px;
		}
	}
}
.services {
	.ant-skeleton,
	.serviceCard {
		width: 289px;
		height: 254px;
	}
	.ant-skeleton{
		border: 1px solid #BFBFBF;
		padding: 10px;
		height: 254px;
	}
	.serviceCard {
		margin: 0 auto;
		.earth{
			font-size: 24px;
			color: #6F6F6F;
			cursor: pointer;
		}
		.optimus-card-body{
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.mailService{
			font-weight: 700;
			font-size: 25px;
			.icon-email{
				font-size: 20px;
			}
		}
		.bingImg{
			width: 250px;
		}

	}
}
.editContries,
.serviceModal{
	.ant-modal-close{
		padding: 17px 0px;
	}
	.ant-modal-footer{
		border: none;
		padding: 10px 44px 38px 38px;
	}
	.ant-modal-body{
		padding: 24px 44px 45px 38px;
	}
	.ant-modal-title{
		font-size: 32px;
		font-weight: 700;
		.earth{
			font-size: 24px;
			color: #6F6F6F;
			cursor: pointer;
			padding-right: 20px;
		}
	}
	.ant-modal-header{
		border-color: #000000;
		padding: 32px 24px 32px 44px;
	}
	label,
	.ant-divider{
		font-size: 14px;
		font-weight: 700;
		color: #000000;
		&:after{
			border-top-color: #000000;
		}
		&:before{
			width: 0;
		}
		.ant-divider-inner-text{
			padding-left: 0;
		}
	}
	.mailDisclamer {
		font-size: 10px;
		font-style: italic;
		.asterisc {
			font-size: 12px;
			color: #FF0000;
			padding: 0 5px;
		}
	}
	&.sendGrid{
		img{
			height: 35px;
		}
	}
	&.mailService{
		.ant-space-item{
			font-weight: 700;
			font-size: 32px;
		}
		.icon-email{
			font-size: 19px;
		}
	}
	&.twilio{
		img{
			height: 47px;
		}
	}
	&.bing{
		img{
			width: 170px;
		}
	}
}

.countryForm{
	width: 100%;
	.ant-modal-close{
		padding: 17px 0px;
	}
	.ant-modal-header{
		padding: 32px 24px;
	}
	.countryList {
		height: 253px;
		overflow-y: scroll;
		margin-top: 10px;
		.ant-list-item-action {
			.anticon{
				color: #00000040;
			}
		}
	}
}