.manageModal {
	.eventSelect {
		margin-top: 10px;
	}
	.commandFormRow{
		margin-top: 10px;
		&.commandList{
			padding: 5px;
			overflow-y: scroll;
			height: 200px;
		}
	}
}
