.modelProfile {
	.profilesGrid{
		padding: 10px;
	}
	.profiles, .newProfile {
		width: 100%;
		height: 212px;
		margin: 0 auto;
		display: block;
		margin-top: 20px;
	}
	.profiles {
		.body{
			text-align: center;
			height: 65px;
			.iconModal{
				cursor: pointer;
			}
		}
		.DefaultCrown{
			font-size: 20px;
		}
	}
}
