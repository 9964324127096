.login {
	.login-form-forgot {
		float: right;
	}
	.login-form-button {
		width: 100%;
	}
	.imageLogo {
		width: 210PX;
		margin: 0 auto;
		display: block;
	}
}

.resetPassword{
	height: 100%;
	.resetform{
		margin: auto;
	}
	.login-form-button{
		width: 100%;
	}
}