.map-devices {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .container {
        width: 75rem;
        height: 42rem;
    }
}
