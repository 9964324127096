.breadcrumb {
    max-height: 28px;
    padding-bottom: 4px;
    margin-top: -6px;
    background: white;

    .crumb {

        button {
            padding: 0;
            color: gray;
            height: auto;
            font-size: 12px;
        }
        button:hover {
            color: #1990ff;
        }
    }
}

@media only screen and (max-width: 767px) {
    .breadcrumb {
        display: none;
    }
}
