.driver-list {
	.driver-col-header {
		text-align: center;
		.driver-space-header {
			width: 100%;
		}
	}
	.driver-table {
		padding-top: 30px;
	}
}

.route-driver-information {
	.description {
		.description-item {
			background: #f0f0f0;
			border-radius: 4px;
		}
	}
}

.floating-driver-list,
.floating-driver-task-list {
	width: 100%;
}

.floating-driver-task-list {
	&-menu {
		text-align: right;
		cursor: pointer;
		padding: 0 15px;
	}
}

.floating-driver-list {
	&-space {
		width: 100%;
	}
}

.drivers-device-modal.ant-modal {
	top: 46px;
}

.drivers-device-modal {
	.ant-modal-header {
		div {
			font-size: 20px;
    		font-weight: 700;
    		text-transform: capitalize;
		}
	}
	.ant-modal-body {
		padding-top: 10px;
	}
}

.view.driver-list {
	padding-bottom: 0 !important;
}

.cellular-devices {
	li.ant-dropdown-menu-item.ant-dropdown-menu-item-active {
		color: #1890ff;
	}
	li.ant-dropdown-menu-item {
		color: #2e2e2ed9;
	}
}

.task-card {
	.ant-card-extra {
		padding: 8px 0;
	}
	.ant-card-head-title {
		padding: 0;
	}
	.scheduler-btn {
		padding: 2px 8px;
	}
}

.floating-task-list, .floating-driver-list, .floating-panel.right-top {
	.ant-divider-plain.ant-divider-with-text {
		color: #afafaf;
	}
}

.icon-md {
	[class^="icon-"] {
		font-size: 20px !important;
	}
	.icon-pin {
		font-size: 16px !important;
		padding: 3px;
	}
}

.icon-sm {
	[class^="icon-"] {
		font-size: 14px !important;
	}
}

.otp-modal{
	&-description {
		width: 18rem;
	}
	&-icon {
		display: flex;
		justify-content: center;
		span {
			font-size: 7rem;
		}
	}
}

.route-users-page {
	&-actions {
		display: flex;
	
		button {
			color: #AFAFAF;
		}
	}
	&-container {
		height: 100%;
	}
	&-desktop-view {
		span.ant-tag {
			width: 6.2rem;
			text-align: center;
		}
		&-actions {
			gap: 12px;
		}
	}
	&-mobile-view {
		height: 100%;

		&-container {
			height: 100%;
		}
		&-search {
			margin: 0rem 1rem;
			height: auto;
		}
		button.ant-btn.ant-btn-circle.ant-btn-primary.ant-btn-lg.ant-btn-icon-only.route-users-page-mobile-view-btn {
			position: fixed;
			bottom: 1rem;
			right: 2.7rem;
			height: 56px !important;
			width: 56px !important;
			z-index: 10;

			svg {
				height: 24px;
				width: 24px;
			}
		}
		&-list {
			height: 88%;
			overflow-y: auto;
			overflow-x: hidden;
			padding: 5px 0;
			padding-bottom: 3.5rem;
		}
		&-actions {
			gap: 34px;
		}
	}
	&-otp {
		width: 100%;
		button {
			padding: 0;
		}
	}
}

.driver-user-card {
	box-shadow: 0 0 4px 0 #dcdcdcd6;
    padding: 1rem 1rem;
    margin: 0.5rem 1rem;
    border-radius: 4px;
	margin-bottom: 1rem;
	padding-bottom: 10px;

	&-actions {
		justify-content: center;
		margin-top: 8px;
	}
	&-icon {
		color: #1890FF;
	}
	&-status-tag {
		text-align-last: right;
	}
}

.share-app {
	&-container {
		padding: 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;

		.ant-row.share-app-icon {
			height: 5rem;
			width: 5rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&-text {
			border-right: 1px solid #AFAFAF54;
		}
	}
	&-icon {
		&-sms-spinner {
			height: 5rem;
			display: flex;
			align-content: center;
			justify-content: center;
		}
		span {
			font-size: 80px;
		}
	}
	.ant-spin.ant-spin-lg.ant-spin-spinning.share-app-icon-spinner {
		display: flex;
		align-items: center;
	}
}

button.ant-btn.ant-btn-text.ant-btn-icon-only {
	.share-round {
		color: white;
		background: #afafaf;
		padding: 3px 3px;
		border-radius: 18px;
		font-size: 9px;	
	}
	&.button-SHARE {
		padding-top: 0;
	}
	&:hover {
		.share-round {
			background: #000000d9;
		}	
	}
}

.review-schedule {
	display: flex;
    flex-direction: column;
    gap: 12px;
	&-days {
		padding-left: 5rem;
	}
	form {
		gap: 1rem;
		display: flex;
		flex-direction: column;
	}
}

.team-members-progress {
	&.container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.ant-spin-nested-loading {
			width: 60%;
		}
		.progress-title-container {
			.progress-title {
				margin: 0;
				letter-spacing: 0.3;
				font-size: 12px;
				width: 100%;
			}
		}
		.progress-values {
			display: flex;
			flex-direction: row;
			width: 100%;
			align-items: center;
			.progress-bar {
				display: flex;
				justify-content: center;
				align-items: center;
				flex: 3;
			}
			.progress-bar-text {
				margin-left: 5px;
				font-size: 11px;
				display: flex;
				flex: 1;
			}
		}
	}
	&.capacity-alert-modal {
		.ant-modal-body {
			display: flex;
			height: 350px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 30px;
		}
		.animation-section {
			flex: 2;
			position: relative;
			.animation {
				width: 180px;
				height: 180px;
				position: absolute;
				left: 50%;
				right: 50%;
				top: 50%;
				bottom: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.text-section {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;
			.description {
				color: #000;
				font-size: 20px;
				font-style: normal;
				line-height: normal;
			}
			.secondary-description {
				font-size: 16px;
			}
		}
	}
	&.billing-modal {
		.ant-modal-body {
			display: flex;
			flex-direction: column;
			gap: 22px;
		}
		.input-section {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			.seats-input {
				width: 30%;
			}
		}
		.billing-description-container {
			padding-top: 0.5rem;
			.billing-description {
				font-size: 0.84rem;
				color: #757575;
				word-break: break-all;
			}
		}
		.total-section {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}
}

.route-users-page-desktop-table-container {
	.ant-table-container table>thead>tr:first-child th:last-child {
		text-align: center;
	}
	td.ant-table-cell.flex {
		justify-content: center;
	}
}

.route-users-page-desktop-view-actions {
    gap: 12px;
    width: 100%;
    justify-content: space-evenly;
}

@media only screen and (max-width: 767px) {
	.otp-modal {
		.ant-modal-content {
			width: 22rem;
			margin: auto;
			border-radius: 4px;
		}
		.ant-modal-body {
			border-radius: 4px;
		}
		&-description {
			width: 16rem;
			text-align: center;
		}
	}

	.cellular-device-modal.ant-modal {
		top: 0;
		margin: 0;
	}

	.ant-col.ant-col-24.route-users-page-mobile-view-list {
		height: 84vh;
	}

	.driver-user-card {
		&-status-tag {
			span.ant-tag.ant-tag-red {
				margin-right: auto;
			}
		}
		&-list {
			height: 88%;
			overflow-y: auto;
			overflow-x: hidden;
			padding: 5px 0;
			padding-bottom: 3.5rem;
		}
		&-actions {
			gap: 8px;
		}
	}

	.review-schedule {
		&-days {
			padding-left: 1rem;
		}
	}
}

@media only screen and (max-width: 767px) {
	.team-members-progress {
		&.container {
			display: flex;
			flex-direction: row;
			padding: 10px 0px 10px 0px;
			margin-left: 1rem;
			margin-right: 1rem;
			width: 100%;
			.progress-title {
				display: flex;
				margin: 0;
				letter-spacing: 0.6;
				font-size: 1rem;
				padding-right: 10px;
			}
			.progress-values {
				display: flex;
				flex-direction: row;
				flex: 1;
				.progress-bar {
					display: flex;
					align-items: center;
					flex: 2;
				}
				.progress-bar-text {
					margin-left: 5px;
					font-size: 0.8rem;
					display: flex;
				}
			}
		}
		&.capacity-alert-modal {
			height: 100vh;
			.ant-modal-body {
				height: 100vh;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 1rem;
				padding-top: 10rem;
				background-color: #EEE;
			}
			.animation-section {
				flex: 0;
				bottom: 9rem;
				.animation {
					width: 300px;
					height: 300px;
					position: absolute;
				}
			}
			.text-section {
				flex: 0;
				.description {
					text-align: center;
					color: #000;
					font-size: 1rem;
					font-style: normal;
					line-height: normal;
				}
			}
			.button-section {
				flex: 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 10px;
				width: 100%;
				.button-capacity-modal {
					height: auto;
					width: 100%;
					padding-left: 5px;
					padding-right: 5px;
					font-size: 1.1rem;
				}
			}
		}
		&.billing-modal {
			.ant-modal-content {
				height: 100vh;
				.ant-modal-body {
					padding-left: 3rem;
					padding-right: 3rem;
					height: 100vh;
					padding-top: 6rem;
					display: flex;
					flex-direction: column;
					gap: 4rem;
					background-color: #EEE;
				}
			}
			.billing-text {
				font-size: 1.1rem;
			}
			.billing-button {
				height: auto;
				font-size: 1.1rem;
				width: 50%;
			}
			.title-section {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				gap: 1rem;
				.title-text {
					font-size: 1.4rem;
					font-weight: bold;
					text-align: center;
				}
			}
			.divider {
				width: 100%;
				margin-top: 0;
				margin-bottom: 0;
			}
			.values-section {
				display: flex;
				flex-direction: column;
				gap: 1.5rem;
				.input-section {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					.seats-input {
						width: 50%;
						font-size: 1.1rem;
					}
				}
				.total-section {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
				}
			}
			.billing-description-container {
				padding-top: 0.5rem;
				.billing-description {
					font-size: 0.84rem;
					color: #757575;
					word-break: break-all;
				}
			}
			.button-section {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
				gap: 10px;
				.billing-button {
					width: 100%;
				}
			}
		}
	}
}

.newTemplate{
	width: 100%;
	height: 265px;
	margin-top: 10px;
}

.templateCard {
	margin: 10px 0;
	.description {
		padding-left: 5px;
		color: #1890ff;
		cursor: pointer;
		font-size: 12px;
	}		
	.unitDeplay {
		text-align: center;
		padding: 0 0 10px 0;
	}
	.alertIcons {
		padding: 0;
		text-align: center;
	}
	.disabledDelete{
		color: rgba(0, 0, 0, 0.25);
	}
	.iconCar{
		width: 21px;
		&.iconCarRed{
			color:#f5222d;
		}
	}
}

.formContainer {
	max-width: auto;
	margin: auto;
	padding: 25px;
	background: #fff;
	border-radius: 10;
	box-shadow: 0 2px 8px rgba(0,0,0,0.15); 
}