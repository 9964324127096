.sensors {
  .addSensorButton {
    width: 100%;
    height: 175px;
    margin-top: 15px;
  }

  .sensorDivider {
    margin-bottom: 0px !important;
  }

  .hidden {
    display: none;
  }
  .tankSamplesContainer{
    max-height:150px;
    width:100%; 
    overflow-y:auto;
    .sample{
      padding-bottom: 4px;
      .delete{
        padding-top: 4px;
        font-size: 20px;
      }
    }
  }
  .addSampleContainer{
    .button{
      width: 200px;
    }
    padding-top: 5px;
    padding-left: 15px;
  }
  .headers{
    padding-bottom: 8px;
    .options{
      width: 89px;
      height: 30px;
    }
  }
}
.g2-tooltip {
  position: absolute;
  visibility: hidden;
  z-index: 8;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: rgb(174, 174, 174) 0px 0px 10px;
  border-radius: 3px;
  color: rgb(87, 87, 87);
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", SimSun, sans-serif;
  line-height: 20px;
  padding: 10px 10px 6px;
  left: 312.014px;
  top: 135.014px;
}

.g2-tooltip-title {
  margin-bottom: 4px;
}
.g2-tooltip-list {
  margin: 0px;
  list-style-type: none;
  padding: 0px;
}

.icon-circle {
  &.active {
    color: #52c41a;
  }
  &.inactive {
    color: #f5222d;
  }
}

.icon-container {
  text-align: center;
}

.icon-canbus {
  font-size: 33px;
  color: #444444;
}

.canbus-action {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}