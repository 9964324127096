.player {
	position: relative;
	width: 100%;
	height: 100%;

	&-map {
		width: 100%;
		&-information-open{
			height: 65%;
		}
		&-information-closed{
			height: 100%;
		}
	}
	
	&-footer {
        bottom: 3rem;
        position: absolute;
        left: 0.4rem;
        z-index: 1000;
        color: white;
        padding: 1rem;
        width: 95%;
    }
}
.player-speed-indicator {
	display: flex;
	align-items: center;	
  }

  .indicator-Player-Text {
	color:#108ee9
  }
  
  .hide-devices-info {
	height: 33%;
	display: block;
  }
  
  .hide-devices-info-hidden {
	height: 0%;
	display: none;
  }
  
  .tabs-container {
	padding-left: 20px;
	padding-right: 10px;
  }

.control-panel-player {
	width: 25.7rem;
	border-width: 10px;
	border-color: black;
	border-radius: 4px;
	position: absolute;
	top: 0.4rem;
	left: 0.4rem;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	background: #ffffff;
	box-shadow: $panel-shadow;
}

.deviceInformation {
	background: #fff;
	.table {
		height: 100%;
	}
	.ant-table-content {
		overflow-y: auto;
		height: calc(40vh - 160.5px);
		margin: 0 !important;
	}
	.ant-table-row {
		cursor: pointer;
	}
	.poiToggle {
		width: 200px;
		height: 20px;
		z-index: 999;
		font-size: 12px;
		margin-bottom: 10px;
		display: 'inline-block';
		position: absolute;
		transform: translateX(-50%);
		left: 50%;
		margin: 0 auto;
		right: calc(50vw - 70px);
		text-align: center;
		background: white;
		font-weight: bold;
		cursor: pointer;
		border: 1px solid #108ee9;
		box-shadow: 0px 2px 6px grey;
		.arrowToggle {
			margin-left: 3px;
		}

		&.hidden {
			bottom: 2%;
		  }
		
		&.visible {
			bottom: 36%;
		  }
	}
}

.playerDivider {
	display: flex !important;
    margin: 0px 0 !important;
    color: rgba(0, 0, 0, 0.85) !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    white-space: nowrap !important;
    text-align: center !important;
    border-top: 0 !important;
    border-top-color: rgba(0, 0, 0, 0.06) !important;
}
  
.playerMapCardTitle {
	font-size: 12px;
	display: inline-block;
	text-indent: 0%;
	width: 100%; 
	white-space: nowrap; 
	overflow: hidden;
	text-overflow: ellipsis;
  }
  
  .playerMapCardContainer {
	width:  100%;
	height: 90px;
	aspect-ratio: 2;
	text-align: center;
	border-radius: 8px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	border-bottom: none;
  
	.playerCardContent {
	  color: white;
	  font-size: 18px;
	  max-height: 15px;
	}
  }
  
  .playerMapIconCard {
	font-size: 25px;
	color: #2E2E2E;
  
	&--primary {
	  color: #FFFFFF;
	}
  
	&--success {
	  color: #388E3C;
	}
  
	&--warning {
	  color: #F39C12;
	}
  
	&--danger {
	  color: #A52019;
	}
  }
  
  .playerMapTextIcon {
	font-size: 12px;  
	color: #2E2E2E;
	font-weight: bold;  
  }
  
  .playerMapTextIcon--primary {
	color: #FFFFFF;
  }
  
  .playerMapTextIcon--success {
	color: #388E3C;
  }
  
  .playerMapTextIcon--warning {
	color: #F39C12;
  }
  
  .playerMapTextIcon--danger {
	color: #A52019;
  }
  .List-canbus {
	padding-bottom: 10px;
	max-height: 200px;
	padding-right: 25px;
	overflow-y: auto;
  }