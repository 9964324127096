#root {
	height: 100vh;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
	white-space: nowrap !important;
}
.center-text {
	text-align: center;
}
.right-text {
	text-align: right;
}
.ant-modal {
	.ant-form-item {
		margin-bottom: 10px;
	}
}
.deleting-row td {
	background-color: red !important;
	opacity: 0.5;
	color: white !important;
}
.backBtn {
	cursor: pointer;
	color: #1890ff !important;
	font-size: 20px;
}
.loading-card {
	color: #1890ff;
	font-size: 20px;
	&.hidden {
		display: none;
	}
}
.table {
	.control {
		padding: 5px 0 5px 0;

		> button:not(:first-child) {
			margin-left: 10px;
		}
		.searchBar {
			width: 400px;
		}
		.iconMoon {
			padding-right: 5px;
		}
	}
	.status {
		text-align: center !important;
	}

	.extraButton {
		margin-left: 10px;
	}
	.ant-table-body {
		overflow-x: scroll;
	}
	.commonTable {
		.ant-table-body {
			height: 100%;
		}
	}
}
.MobileTable {
	.ant-spin-container > div,
	.control {
		margin: 5px 0px;
	}
}
.sessionControl {
	padding-bottom: 5px;
}
@mixin sentence-case() {
	text-transform: lowercase;

	&:first-letter {
		text-transform: uppercase;
	}
}

.sentenceCase {
	@include sentence-case();
}
.markerLabel {
	color: black;
	font-family: 'Lucida Grande', 'Arial', sans-serif;
	font-size: 10px;
	font-weight: bold;
	text-align: center;
	border: 2px solid black;
	padding-left: 2px;
	padding-right: 2px;
	opacity: 0.7;
	white-space: nowrap;
	&.hideLabel {
		display: none !important;
	}
}
.profilePicture {
	img {
		border-radius: 100%;
	}
}
.profileModal {
	.profileMenu {
		display: none;
		min-height: 300px;
		padding: 5px;
		border-left: 1px solid #e9e9e9;
		&.selected {
			display: block;
		}
	}
	.profileNav {
		padding: 5px;
	}
	.menuLink {
		cursor: pointer;
		font-weight: bold;
		padding: 5px;
	}
	.myProfile {
		img {
			min-height: 150px;
			min-width: 150px;
			max-height: 150px;
			max-width: 150px;
		}
		.profileForm {
			display: none;
			.editProfileAction {
				text-align: center;
			}
		}
		.side-icon {
			cursor: pointer;
		}
		.profileView {
			display: block;
			img {
				border-radius: 100%;
			}
			.profileUpload {
				.ant-spin-container {
					overflow: visible !important;
				}
			}
			.ant-row-flex {
				padding: 10px 0px;
			}
			.avatar-uploader,
			.avatar-uploader-trigger,
			.loaded-img {
				width: 150px;
				height: 150px;
				&:hover {
					opacity: 0.7;
				}
				&:hover:before {
					content: '\E627';
					font-family: 'anticon' !important;
					position: absolute;
					top: 55px;
					left: 65px;
					font-size: 30px;
				}
			}
			.avatar-uploader {
				display: block;
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
			}
			.avatar-uploader-trigger {
				display: table-cell;
				vertical-align: middle;
				font-size: 28px;
				color: #999;
			}
			.avatar-uploader,
			img {
				border-radius: 100%;
			}
		}
		&.edit {
			.profileForm {
				display: block;
			}
			.profileView {
				display: none;
			}
		}
	}
}

.informationImage {
	width: 90px;
}

.cursorPointer {
	cursor: pointer;
}

.providerTable {
	.ant-table-content {
		overflow-y: scroll;
	}
}

.popover {
	width: 336px;
	:global(.ant-popover-inner-content) {
		padding: 0;
	}
}

.noticeButton {
	cursor: pointer;
	display: inline-block;
	transition: all 0.3s;
}

.icon {
	font-size: 16px;
	padding: 4px;
}

.tabs {
	:global {
		.ant-tabs-nav-scroll {
			text-align: center;
		}
		.ant-tabs-bar {
			margin-bottom: 4px;
		}
	}
}
.noticeList {
	max-height: 400px;
	overflow: auto;
	.item {
		transition: all 0.3s;
		overflow: hidden;
		cursor: pointer;
		padding-left: 24px;
		padding-right: 24px;

		.meta {
			width: 100%;
		}

		.avatar {
			background: #fff;
			margin-top: 4px;
		}

		&.read {
			opacity: 0.4;
		}
		&:last-child {
			border-bottom: 0;
		}
		&:hover {
			background: #e6f7ff;
		}
		.title {
			font-weight: normal;
			margin-bottom: 8px;
		}
		.description {
			font-size: 12px;
			line-height: 1.5;
		}
		.datetime {
			font-size: 12px;
			margin-top: 4px;
			line-height: 1.5;
		}
		.extra {
			float: right;
			color: #00000073;
			font-weight: normal;
			margin-right: 0;
			margin-top: -1.5px;
		}
	}
}

.notFound {
	text-align: center;
	padding: 73px 0 88px 0;
	color: #00000073;
	img {
		display: inline-block;
		margin-bottom: 16px;
		height: 76px;
	}
}

.clear {
	height: 46px;
	line-height: 46px;
	text-align: center;
	color: #000000a6;
	transition: all 0.3s;
	cursor: pointer;
	border-top: 1px solid #e8e8e8;
	border-radius: 0 0 4px 4px;

	&:hover {
		color: #000000d9;
	}
}

img {
	object-fit: contain !important;
}

.optimus-card {
	border-color: #00000040;
	.optimus-card-body {
		height: 170px;
		filter: grayscale(100%);
	}
	.ant-card-body {
		padding: 0;
	}
	.ant-card-head {
		border-bottom: none;
		padding: 0;
		max-height: 51px;
		.ant-card-head-title,
		.ant-card-extra {
			padding: 14px 12px;
		}
	}
	.optimus-card-actions {
		button {
			font-weight: 600;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			&:disabled {
				margin: 0 !important;
			}
		}
	}
	&.optimus-card-active {
		border-color: #1890ff;
		.optimus-card-body {
			filter: grayscale(0);
		}
		.ant-card-actions {
			background: #1890ff;
			border-color: #1890ff;
		}
	}
}

.capitalize {
	text-transform: capitalize;
}

.driver-table {
	td.flex {
		display: flex;
	}
}

.form-label {
	font-weight: 600;
	color: #454545;
	margin-bottom: 2px;
}

.success-message {
	.ant-message-notice-content {
		padding: 0;
	}
}

.edit-icon {
	color: #1890ff;
	padding-left: 6px;
}

.row-button-actions {
	width: 100%;
	margin-top: 10px;
}

.action-button {
	margin-left: 10px;
}

.loading {
	text-align: center;
	background: #ffff;
}